<!-- Title -->
<div class="mb-2 text-2xl font-extrabold tracking-tight leading-tight text-center">{{'AUTH.FORGOT.TITLE'}}</div>
<p class="text-md">{{ 'AUTH.FORGOT.INFO' | transloco }}</p>

<!-- Alert -->
<!-- TODO: show error here-->


<!-- Sign in form -->
<form class="mt-8" [formGroup]="forgotForm" #forgotNgForm>

    <!-- username field -->
    <mat-form-field class="w-full"  appearance="outline">
        <mat-label>E-Mail / Username</mat-label>
        <input id="username" matInput [formControlName]="'username'">
        <mat-error *ngIf="forgotForm.get('username')?.hasError('required')">
            This Field is required
        </mat-error>
    </mat-form-field>

    <button class="mat-button-large w-full mt-6" mat-flat-button color="primary" [disabled]="forgotForm.disabled"
        (click)="sendResetLink()">
        <span *ngIf="!forgotForm.disabled">
            Sign in
        </span>
        <mat-progress-spinner *ngIf="forgotForm.disabled" [diameter]="24"
            [mode]="'indeterminate'"></mat-progress-spinner>
    </button>
    <!-- Actions -->
    <div class="inline-flex items-center gap-2 w-full mt-3 text-sm">
        <div class="mx-auto inline-flex gap-2">
            <span>Already have an Account?</span> <a class="font-normal hover:underline"
                [routerLink]="['/sign-in']">Sign in
            </a>
        </div>
    </div>

</form>