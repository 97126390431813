import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MainNavigationComponent } from '../navigation/main-navigation/main-navigation.component';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NotificationComponent } from "../notification/notification.component";
import { UserComponent } from "./user/user.component";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingService } from 'app/service/loading.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  standalone: true,
  animations: [],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MainNavigationComponent,
    MatListModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatToolbarModule,
    AsyncPipe,
    RouterModule,
    FormsModule,
    NotificationComponent,
    UserComponent
]
})
export class LayoutComponent {
  private breakpointObserver = inject(BreakpointObserver);
  isCollapsed = false;
  isLoading = new BehaviorSubject<boolean>(false);
  private _unsubscribeAll = new Subject<null>();

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  constructor(private _loadingService: LoadingService, private _changeDetector:ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this._loadingService.isLoading
    .pipe(
      takeUntil(this._unsubscribeAll)
    )
    .subscribe(loading =>{
      this.isLoading.next(loading);
      this._changeDetector.detectChanges();
    });
  }
}
