import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule} from '@angular/material/badge';
import { MessageData, NotificationService } from 'app/service/notification.service';
import { Subject, Subscription, of, retry, retryWhen, takeUntil } from 'rxjs';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TransactionSourceService } from 'app/service/transactionSource/transactionSource.service';
import { DataImportStatus, TradeFileEvent, TradeFilePayload } from 'app/types/domain/transaction';
import { TransactionSource } from 'app/types/extended.transaction.types';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTooltipModule,
    CommonModule,
    TranslocoModule,
    RouterModule
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent implements OnInit, OnDestroy{
  
  notifications:Map<number,MessageData> = new Map<number, MessageData>();
  sources: TransactionSource[] = [];
  #unsubscribeAll = new Subject<null>();

  subscription: Subscription|undefined;

  @ViewChild(MatMenu) menu!: MatMenu;

  constructor(private _notificationService: NotificationService, private _transactionSourceService: TransactionSourceService) { }



  ngOnInit(): void {
    this.subscription = this._notificationService.createEventSubscription()
    .pipe(
      takeUntil(this.#unsubscribeAll),
    )
    .subscribe({
      next: (data: MessageData) => {
        const payload:TradeFilePayload = data.payload as TradeFilePayload;
        const fileId = payload.fileId;
        if(!fileId){
          return;
        }
        let matchingSource:TransactionSource|undefined = this.sources.find((source:TransactionSource)=> source.sourceId === payload.fileId);
        
        if(matchingSource === undefined){
          matchingSource = {
            fileName: payload.fileName, 
            sourceId: payload.fileId, 
            lastTradeFileEvent: {
              ...payload as TradeFileEvent,
              created: {
                date: new Date()
              }
            }
          };
          this.sources.unshift(matchingSource);
          this._transactionSourceService.transactionSources = this.sources;
        }
        matchingSource.lastImportStatus = payload.importStatus;
        if(payload.errorInfo !== undefined){
          matchingSource.error = true;
        }
        this.notifications.set(fileId, data);  
      },
      error: (error:any) => {
        
      }}  
    );

    this._transactionSourceService.transactionSources
    .pipe(
      takeUntil(this.#unsubscribeAll)
    )
    .subscribe(
      (sources:TransactionSource[])=> {
        this.sources = sources ///.filter((source:TransactionSource)=> source);
      }
    )
    this._transactionSourceService.queryTransactionSources({pager:{firstResult:0, pageSize:5}, orderBy: 'sourceId', orderDir: 'DESC'});  
  }

  ngOnDestroy(): void {
    this.#unsubscribeAll.next(null);
    this.#unsubscribeAll.complete();
  }

  shortenFileName(fileName:string):string{
    return fileName.length > 20 ? fileName.substring(0, 20) + '...' + fileName.substring( fileName.length - 4 ): fileName;
  }

  markNotificationAsRead(fileId?:number){
    if(fileId === undefined){
      this.notifications.clear();
    } else{
      this.notifications.delete(fileId);
    }
  }
  statusNotFinal(source: TransactionSource): boolean{
    return source.lastImportStatus !== DataImportStatus.IMPORTED;
  }
  hasError(source: TransactionSource): boolean{
    return source.error 
    || (this.statusNotFinal(source) 
    && new Date(source.lastTradeFileEvent?.created?.date || '1970-01-01').getTime() < new Date().getTime() - 1000 * 60 * 30);
  }


}
