import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoadingService } from 'app/service/loading.service';
import { finalize, tap } from 'rxjs';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  if(req.url.includes('pool')){
    // skip loading for pool requests
    return next(req);
  }
  const loadingService = inject(LoadingService);
  loadingService.setLoading(true, req.url);
  
  return next(req).pipe(
    finalize(()=>{
      loadingService.setLoading(false, req.url);
    })
    );
};
