<mat-sidenav-container class="sidenav-container" autosize>

  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <mat-icon [svgIcon]="isCollapsed? 'ntrde:black_mini':'ntrde:black'" class="size-10"
        [class]="isCollapsed?'w-10':'w-40'"></mat-icon>
      <span class="text-l font-medium ml-1 tracking-tight" *ngIf="!isCollapsed">NTRDE</span>
    </mat-toolbar>
    <button *ngIf="(isHandset$ | async) === false"
      class="absolute top-4 -right-4 bg-white border-solid border border-black rounded h-8 w-8 flex justify-center items-center z-20 collapse-button"
      (click)="isCollapsed = !isCollapsed" color="white">
      <mat-icon [svgIcon]="isCollapsed ? 'chevron_right' : 'chevron_left' "></mat-icon>
    </button>

    <main-navigation [collapsed]="isCollapsed"></main-navigation>

  </mat-sidenav>
  <mat-sidenav-content>
    <div>
      <mat-toolbar class="sticky z-10 top-0 p-2">
        <mat-toolbar-row>
          @if (isHandset$ | async) {
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
            <mat-icon aria-label="Side nav toggle icon" svgIcon="menu"></mat-icon>
          </button>
          }
          <!-- TODO: Searchbar goes here-->
          <!--mat-form-field class="w-full mx-4" subscriptSizing="dynamic">
            <mat-icon matIconPrefix svgIcon="search"></mat-icon>
            <input matInput type="text" placeholder=" Not Yet implemented ...">
          </mat-form-field-->
          <span class="spacer flex-auto"></span>
          <app-notification></app-notification>
          <button mat-icon-button>
            <mat-icon svgIcon="mat_outline:chat"></mat-icon>
          </button>
          <app-user></app-user>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-progress-bar *ngIf="isLoading | async" mode="indeterminate"></mat-progress-bar>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>