import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { AuthService } from '../service/auth.service';
import { catchError, throwError } from 'rxjs';
import { AuthUtils } from '../utils/auth.utils';

export const authInterceptor: HttpInterceptorFn = (req, next) => {

  const configService = inject(ConfigService);
  const authService = inject(AuthService);

  if (
    !req.url.startsWith(configService.getBaseUrl()) ||
    req.url.includes('auth')
  ) {
    return next(req);
  }
  // Clone the request object
  let newReq = req.clone();
  if (
    authService.sessionToken?.access_token &&
    !AuthUtils.isTokenExpired(authService.sessionToken.access_token)
  ) {
    newReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        'Bearer ' + authService.sessionToken.access_token
      ),
    });
  }else if(
    authService.sessionToken?.access_token &&
    AuthUtils.isTokenExpired(authService.sessionToken.access_token)
    ){
      console.debug('Token expired, trying to refresh token');
    authService.signInUsingToken()
  }

  // Response
  return next(newReq).pipe(
    catchError((error) => {
      // Catch "401 Unauthorized" responses
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Sign out
        authService.signOut();

        // Reload the app
        location.reload();
      }

      return throwError(error);
    })
  );
};
