<mat-toolbar>
    <mat-icon [svgIcon]="'ntrde:black'" class="size-10" [class]="'w-40'"></mat-icon>
    <span class="text-l ml-1 font-medium tracking-tight">NTRDE</span>
</mat-toolbar>
<div class=" flex flex-col justify-center bg-transparent md:h-[80vh] w-full">

    <div class="md:flex 
        mx-auto
        md:items-center 
        md:justify 
        w-full
        max-w-max
        sm:w-auto 
        md:w-1/2 
        py-8 px-4 
        sm:p-12 
        md:p-16 
        sm:rounded-2xl 
        md:rounded-none 
        sm:shadow 
        sm:bg-card
        bg-white">
        <div class="flex flex-col max-w-md mx-auto items-center justify-center text-center">

            <router-outlet></router-outlet>

        </div>
    </div>
</div>