import { ConfigService, MockApiService } from "@yukawa/chain-base-angular-client";
import { transactions } from "./data";
import { Injectable, inject } from "@angular/core";
import { Transaction } from "app/types/domain/transaction";
import { QueryResult } from "@yukawa/chain-base-angular-domain";


@Injectable({
    providedIn: 'root'
  })
export class TransactionMockApi {

    private _mockApiService: MockApiService = inject(MockApiService);

    private readonly transactions: Transaction[];

    constructor(){
        this.transactions = transactions;
        this.registerHandlers();
    }

    registerHandlers():void{
        this._mockApiService
        .onPost("https://chem.dev.cloud.yukawa.de/api/trade-service/transaction/query")
        .reply((data:any) => {
            console.log('querying transactions', data.request.body);
            let trades = JSON.parse(JSON.stringify(this.transactions));
            return [
                200,
                {
                    items: trades.splice(data.request.body.pager.firstResult, data.request.body.pager.pageSize),
                    rows: this.transactions.length
                }
            ]
        });
        
    }
}