import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoCoreModule } from '@yukawa/chain-base-angular-comp/shared';
import { AuthService } from 'app/service/auth.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    RouterModule,
    TranslocoModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit{
  @ViewChild('forgotNgForm') forgotNgForm!: NgForm;

  forgotForm!: UntypedFormGroup;
  showAlert: boolean = false;
  alert: { type: string; message: string } = {
    type: 'success',
    message: ''
  }

  constructor(
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder
  ){}

  ngOnInit(): void {
    this.forgotForm = this._formBuilder.group({
      'username': ['', Validators.required]
    })
  }

  sendResetLink(): void {
    if (this.forgotForm.invalid) {
      return;
    }

    this.forgotForm.disable();

    this.showAlert = false;

    // Forgot password
    this._authService
      .forgotPassword(this.forgotForm.get('username')?.value)
      .pipe(
        finalize(() => {
          // Re-enable the form
          this.forgotForm.enable();

          // Reset the form
          this.forgotNgForm.resetForm();

          // Show the alert
          this.showAlert = true;
        })
      )
      .subscribe({
        next: (response) => {
          this.alert = {
            type: 'success',
            message:
              "Password reset sent! You'll receive an email if you are registered on our system.",
          };
        },
        error: (response) => {}
      });
        /*(response) => {
          // Set the alert
          this.alert = {
            type: 'success',
            message:
              "Password reset sent! You'll receive an email if you are registered on our system.",
          };
        },
        (response) => {
          // Set the alert
          this.alert = {
            type: 'error',
            message:
              response.error?.text ||
              response.message ||
              'Something went wrong',
          };
        }
        
      );*/
  }
}
