import { Injectable } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { Observable, RetryConfig, last, lastValueFrom, map, of, retry, tap } from 'rxjs';
import { UserService } from './user/user.service';

import { SseClient, SseOptions } from 'ngx-sse-client'

class RetriableError extends Error { }

export interface MessagePayload {
  fileId: number,
  fileName: string,
  importStatus: string,
  username: string,
}

export interface MessageData{
  payload: MessagePayload;
  name: string;
  module: string;
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  retryConf: RetryConfig = {
    count: 10,
    delay(error:any, retryCount:number) {
      if (error instanceof RetriableError) {
        console.log('Retrying', retryCount);
        return of(true);
      }
      else {
        return of(false);
      }
      
      
    },
  }

  constructor(private _configService: ConfigService, private _userService:UserService,private  _sseClient: SseClient) { }

  createEventSubscription():Observable<MessageData> {
    return this._sseClient.stream(
      this._configService.formatUrl('tradeFileEventUrl')+'/pool-my-events',
      {reconnectionDelay: 1, keepAlive: true} ,
      {body: {}},
      'GET'
    ).pipe(
      map((event:Event)=>{
        if(event.type === 'error' || event instanceof ErrorEvent){
          throw new RetriableError('error');
        }
        const data = JSON.parse((event as MessageEvent).data) as MessageData;
        return data;
      }),
      retry(this.retryConf),
    )
  }
}
