<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon svgIcon="mat_outline:account_circle"></mat-icon>
</button>
<mat-menu #menu="matMenu">
    <div class="px-4">logged in as <span class="font-bold">{{username}}</span></div>
    <a routerLink="sign-out">
        <button mat-menu-item>
            <mat-icon svgIcon="exit_to_app"></mat-icon>
            <span>Logout</span>
        </button>
    </a>
</mat-menu>