import { Component, Injector, OnInit, } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
    MockApiModule,
    setAppInjector,
    setEnvironment,
} from '@yukawa/chain-base-angular-client';
import { environment } from '../environments/environment';
import { InitModule } from '@yukawa/chain-base-angular-client';
import {
    AuthGuardService,
    AuthService,
    LoginRequestEventArgs,
    Role,
    Session,
    SessionChangedEventArgs,
    SessionModule,
    SessionService,
} from '@yukawa/chain-main-angular-session';
import { TranslocoModule,TranslocoService } from '@jsverse/transloco';
import { lastValueFrom } from 'rxjs';
import { locale } from 'moment';

setEnvironment(environment);

const DEFAULT_REDIRECT_URL = '/signed-in-redirect';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterOutlet,
        InitModule, 
        SessionModule, 
        TranslocoModule
    ],
})
export class AppComponent implements OnInit{
  
    /**
     * Constructor
     */
    constructor(
        injector: Injector,
        private _router: Router,
        private readonly _authGuardService: AuthGuardService,
        private _sessionService: SessionService,
        private _translocoService: TranslocoService
    ) {
        setAppInjector(injector);
        //_sessionService.sessionChanged.subscribe(this.sessionChanged, this);
        //_sessionService.auth.loginRequest.subscribe(this.loginRequest, this);
    }

    async ngOnInit(): Promise<void> {
      await this.mergeTranslations((lang: string) => `yukawa/${lang}`);
      //await this.mergeTranslations((lang: string) => `app/${lang}`);
      await this.mergeTranslations((lang: string) => lang);
    }


    public async mergeTranslations(transformLocation: (lang: string) => string): Promise<void>
    {
        await Promise.all(this._translocoService.getAvailableLangs().map(async (lang) =>
        {
            lang = typeof lang === 'string' ? lang : lang.id;
            this._translocoService.setTranslation(
                await lastValueFrom(this._translocoService.load(transformLocation(lang))),
                lang, {
                    merge: true,
                },
            );
        }));
    }

    public initialNavigation(command?: string): Promise<boolean> {
        const urls = new Array<string>();
        const initialRedirectUrl = this.getInitialRedirectUrl(urls);

        // Skip non admins without view rights
        if (
            !this._sessionService.session?.user?.hasRole(Role.admin) &&
            initialRedirectUrl === DEFAULT_REDIRECT_URL
        ) {
            return Promise.resolve(false);
        }

        return this._router.navigateByUrl(
            command && urls.some(url => command.startsWith(url))
                ? command
                : initialRedirectUrl
        );
    }

    /**
     * Returns the first navigation item or default redirect url
     *
     * @param  urls
     * @private
     */
    private getInitialRedirectUrl(urls?: Array<string>): string {
        if (!urls) {
            urls = new Array<string>();
        }
        /*
        for (const _navigationItem of navigation) {
            if (_navigationItem.hidden && _navigationItem.hidden(_navigationItem)) {
                continue;
            }
            if (_navigationItem.link){
              urls.push(_navigationItem.link);
            }
        }
        */
        return urls?.[0] || DEFAULT_REDIRECT_URL;
    }

    private async loginRequest(
        sender: AuthService,
        ea: LoginRequestEventArgs
    ): Promise<void> {
        let redirectUrl: string;
        let queryParams = {};
        if (sender.isAuthenticated) {
            redirectUrl = '/signed-in-redirect';
        } else {
            if (this._authGuardService.lastForbiddenRoute) {
                queryParams = {
                    redirectURL: this._authGuardService.lastForbiddenRoute,
                };
            }

            redirectUrl = '/sign-in';
        }

        ea.signInUrl = redirectUrl;
        await this._router.navigate([redirectUrl], { queryParams });
    }
}
