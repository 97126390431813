import { Transaction } from "app/types/domain/transaction"

export const transactions:Transaction[] = [
    {
        transactionId: 1,
        buyer: "Bayer AG",
        seller: "Seller Inc.",
        product: "Methan",
        quantity: 50,
        quantityUnit: "kg",
        pricePerUnit: 100,
        sourceId: 1
    },
    {
        transactionId: 2,
        buyer: "Buyer 2",
        seller: "Seller 2",
        product: "Product 2",
        quantity: 20,
        quantityUnit: "kg",
        pricePerUnit: 50,
        sourceId: 2
    },
    {
        transactionId: 3,
        buyer: "Buyer 3",
        seller: "Seller 3",
        product: "Product 3",
        quantity: 30,
        quantityUnit: "kg",
        pricePerUnit: 70,
        sourceId: 3
    },
    {
        transactionId: 4,
        buyer: "Buyer 4",
        seller: "Seller 4",
        product: "Product 4",
        quantity: 40,
        quantityUnit: "kg",
        pricePerUnit: 80,
        sourceId: 4
    },
    {
        transactionId: 5,
        buyer: "Buyer 5",
        seller: "Seller 5",
        product: "Product 5",
        quantity: 60,
        quantityUnit: "kg",
        pricePerUnit: 120,
        sourceId: 5
    },
    {
        transactionId: 6,
        buyer: "Buyer 6",
        seller: "Seller 6",
        product: "Product 6",
        quantity: 70,
        quantityUnit: "kg",
        pricePerUnit: 90,
        sourceId: 6
    },
    {
        transactionId: 7,
        buyer: "Buyer 7",
        seller: "Seller 7",
        product: "Product 7",
        quantity: 80,
        quantityUnit: "kg",
        pricePerUnit: 110,
        sourceId: 7
    },
    {
        transactionId: 8,
        buyer: "Buyer 8",
        seller: "Seller 8",
        product: "Product 8",
        quantity: 90,
        quantityUnit: "kg",
        pricePerUnit: 60,
        sourceId: 8
    },
    {
        transactionId: 9,
        buyer: "Buyer 9",
        seller: "Seller 9",
        product: "Product 9",
        quantity: 10,
        quantityUnit: "kg",
        pricePerUnit: 30,
        sourceId: 9
    },
    {
        transactionId: 10,
        buyer: "Buyer 10",
        seller: "Seller 10",
        product: "Product 10",
        quantity: 15,
        quantityUnit: "kg",
        pricePerUnit: 40,
        sourceId: 10
    },
    {
        transactionId: 11,
        buyer: "Buyer 11",
        seller: "Seller 11",
        product: "Product 11",
        quantity: 25,
        quantityUnit: "kg",
        pricePerUnit: 55,
        sourceId: 11
    },
    {
        transactionId: 12,
        buyer: "Buyer 12",
        seller: "Seller 12",
        product: "Product 12",
        quantity: 35,
        quantityUnit: "kg",
        pricePerUnit: 75,
        sourceId: 12
    },
    {
        transactionId: 13,
        buyer: "Buyer 13",
        seller: "Seller 13",
        product: "Product 13",
        quantity: 45,
        quantityUnit: "kg",
        pricePerUnit: 85,
        sourceId: 13
    },
    {
        transactionId: 14,
        buyer: "Buyer 14",
        seller: "Seller 14",
        product: "Product 14",
        quantity: 55,
        quantityUnit: "kg",
        pricePerUnit: 115,
        sourceId: 14
    },
    {
        transactionId: 15,
        buyer: "Buyer 15",
        seller: "Seller 15",
        product: "Product 15",
        quantity: 65,
        quantityUnit: "kg",
        pricePerUnit: 95,
        sourceId: 15
    },
    {
        transactionId: 16,
        buyer: "Buyer 16",
        seller: "Seller 16",
        product: "Product 16",
        quantity: 75,
        quantityUnit: "kg",
        pricePerUnit: 105,
        sourceId: 16
    },
    {
        transactionId: 17,
        buyer: "Buyer 17",
        seller: "Seller 17",
        product: "Product 17",
        quantity: 85,
        quantityUnit: "kg",
        pricePerUnit: 65,
        sourceId: 17
    },
    {
        transactionId: 18,
        buyer: "Buyer 18",
        seller: "Seller 18",
        product: "Product 18",
        quantity: 95,
        quantityUnit: "kg",
        pricePerUnit: 35,
        sourceId: 18
    },
    {
        transactionId: 19,
        buyer: "Buyer 19",
        seller: "Seller 19",
        product: "Product 19",
        quantity: 5,
        quantityUnit: "kg",
        pricePerUnit: 45,
        sourceId: 19
    },
    {
        transactionId: 20,
        buyer: "Buyer 20",
        seller: "Seller 20",
        product: "Product 20",
        quantity: 12,
        quantityUnit: "kg",
        pricePerUnit: 25,
        sourceId: 20
    },
]