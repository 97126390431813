import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { IsActiveMatchOptions, Router, RouterModule } from '@angular/router';
import { NavigationService } from '../../../service/navigation.service';
import { NavigationItem } from '../../../types/navigation.types';

@Component({
  selector: 'main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrl: './main-navigation.component.scss',
  standalone: true,
  imports: [CommonModule, MatListModule, MatButtonModule, MatIconModule, RouterModule]
})
export class MainNavigationComponent implements OnInit{
  @Input()collapsed: boolean = false;
  navigation: NavigationItem[] = [];
  readonly subsetMatchOptions: IsActiveMatchOptions = {
    paths       : 'subset',
    fragment    : 'ignored',
    matrixParams: 'ignored',
    queryParams : 'subset',
}

  constructor(public navigationService: NavigationService, private _router: Router) {
    this.navigation = this.navigationService.navigationItems;
  }

  ngOnInit(): void {
  }

  handleClick(item:NavigationItem):void{
    if(!item.children){
      this._router.navigate([item.link]);
    }else{
      this.navigationService.toggleNavigationItem(item);
    }
    //expandItem
  }
  childHidden(item: NavigationItem){
    return item.hidden !== undefined ? item.hidden(item): false
  }

}