import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  urlMap: Map<string, boolean> = new Map<string, boolean>();

  constructor() { }

  setLoading(loading:boolean, url:string):void{
    
    if(loading){
      this.urlMap.set(url, loading);
    }else if(this.urlMap.has(url)){
      this.urlMap.delete(url);
    }
    this.loading.next(loading);
  }
  
  get isLoading(): Observable<boolean>{
    return this.loading.asObservable();
  }

}
