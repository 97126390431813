<mat-nav-list>
  @for (link of navigation; track link) {

  <ng-container *ngIf="link.hidden !== undefined ? link.hidden(link) : true">
    <a mat-list-item (click)="handleClick(link)" [routerLink]="link.children ? null : link.link"
      routerLinkActive="sidenav-link-active bg-blue-500" [routerLinkActiveOptions]="subsetMatchOptions"
      class="flex items-center" [class.collapsed]="collapsed">
      <mat-icon matListItemIcon [svgIcon]="link.icon || 'home'"></mat-icon>
      <span matListItemTitle *ngIf="!collapsed" class="flex items-center justify-between">
        {{ link.title }}
        <mat-icon *ngIf="link.children" [svgIcon]="link.expanded ? 'arrow_upward' : 'arrow_downward'"></mat-icon>
      </span>
    </a>

    @if(link.children && link.children.every(childHidden) === false){

    <mat-nav-list *ngIf="link.expanded" class="sublist">
      @for (child of link.children; track child) {
      <ng-container *ngIf="child.hidden !== undefined ? child.hidden(child) : true">
        <a mat-list-item [routerLink]="child.link" routerLinkActive="sidenav-link-active bg-blue-500"
          [routerLinkActiveOptions]="{exact: true}" class="flex items-center" [class.collapsed]="collapsed">
          <mat-icon matListItemIcon [svgIcon]="child.icon || 'subdirectory_arrow_right'"></mat-icon>
          <span matListItemTitle *ngIf="!collapsed">{{ child.title }}</span>
        </a>
      </ng-container>
      }
    </mat-nav-list>

    }
  </ng-container>
  }
</mat-nav-list>