
import { CanActivateFn, Routes } from '@angular/router';
import { AuthSignInComponent } from './comp/auth/sign-in/sign-in.component';
import { AuthSignOutComponent } from './comp/auth/sign-out/sign-out.component';
import { LayoutComponent } from './comp/layout/layout.component';
import { NoAuthGuard } from './guards/no-auth.guard';
import { ForgotPasswordComponent } from './comp/auth/forgot-password/forgot-password.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './comp/admin/dashboard/dashboard.component';
import { EmptyLayoutComponent } from './comp/layout/empty-layout/empty-layout.component';
import { TradeComponent } from './comp/admin/trade/trade.component';
import { ResetPasswordComponent } from './comp/auth/reset-password/reset-password.component';
import { of } from 'rxjs';
import { TableContentComponent } from '@yukawa/chain-base-angular-layout';

export const routes: Routes = [
    // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'sign-in',
        component: AuthSignInComponent,
      }
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'sign-out',
        component: AuthSignOutComponent,
      }]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'trade',
        component:TradeComponent,
        loadChildren: ()=> import('app/comp/admin/trade/trade.routes')
      },
      {
        path: 'settings',
        loadChildren: ()=> import('app/comp/admin/settings/settings.routes')
      }
    ],
  },
];
