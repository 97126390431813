import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  inject,
  LOCALE_ID,
  Provider,
} from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { mockApiInterceptor } from 'app/comp/mock-api/mockApi.interceptor';
import { authInterceptor } from 'app/interceptors/auth.interceptor';
import { AuthService } from 'app/service/auth.service';
import { YukawaSplashScreenService } from './splash-screen.service';
import { LoadingService } from './loading.service';
import { loadingInterceptor } from 'app/interceptors/loading.interceptor';

export const provideCore = (useMockApi:boolean): Array<Provider | EnvironmentProviders> => {
  const providers: Array<Provider | EnvironmentProviders> = [
    provideHttpClient(withInterceptors([authInterceptor, loadingInterceptor])),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(AuthService),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(LoadingService),
      multi: true,
    },
    {
      provide : ENVIRONMENT_INITIALIZER,
      useValue: () => inject(YukawaSplashScreenService),
      multi   : true,
    }
  ];
  if(useMockApi){
    providers.push(
      provideHttpClient(withInterceptors([mockApiInterceptor])),
    )
  }
  return providers;
};
