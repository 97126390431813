<button mat-icon-button [matBadge]="notifications.size > 0 ? notifications.size: null"
    [matMenuTriggerFor]="notificationsMenu">
    <mat-icon svgIcon="mat_outline:notifications"></mat-icon>
</button>

<mat-menu #notificationsMenu="matMenu" (closed)="markNotificationAsRead()">
    <ng-container *ngIf="notifications.size > 0 || sources.length > 0; else emptyElement">
        <div mat-menu-item *ngFor="let source of sources | slice:0:5"
            [class.active]="source.sourceId ? notifications.has(source.sourceId): false" class="source"
            [matTooltip]="source.fileName || ''" matTooltipPosition="before"
            [routerLink]="['/trade/source', source.sourceId]">
            <mat-icon svgIcon="mat_outline:text_snippet"></mat-icon>
            <div class="text-xs font-bold">{{ shortenFileName(source.fileName ||
                "") }}</div>
            <div class="text-xs">{{source.sourceId}} {{ source.lastImportStatus }}</div>
            <mat-progress-bar [mode]="hasError(source) ? 'determinate':'indeterminate'"
                *ngIf="statusNotFinal(source) || hasError(source)" [color]="hasError(source) ? 'warn': 'accent'"
                value="100"></mat-progress-bar>
        </div>
        <a [routerLink]="[ 'trade/source' ]">
            <div mat-menu-item class="flex justify-center text-sm">
                See All
            </div>
        </a>
    </ng-container>
    <ng-template #emptyElement>
        <div mat-menu-item disabled>
            <div class="text-xs font-bold">{{ 'NOTIFICATION.EMPTY' | transloco}}</div>
        </div>
    </ng-template>
</mat-menu>